define([
    'lodash',
    'prop-types',
    'componentsCore',
    'coreUtils',
    'backgroundCommon/components/videogl'
], function (
    _,
    PropTypes,
    componentsCore,
    coreUtils,
    vgl) {
    'use strict';

    const {svgFilters, mediaConsts} = coreUtils;

    function createVglEffects(prop) {
        const effects = [
            vgl.effects.transparentVideo()
        ];

        if (('brightness' in prop) || ('contrast' in prop)) {
            const effect = vgl.effects.brightnessContrast();
            effects.push(effect);
            effects.brightnessContrast = effect;
        }

        if (prop.duotoneDark && prop.duotoneLight) {
            const effect = vgl.effects.duotone();
            effects.push(effect);
            effects.duotone = effect;
        }

        if (('hue' in prop) || ('saturation' in prop)) {
            const effect = vgl.effects.hueSaturation();
            effects.push(effect);
            effects.hueSaturation = effect;
        }

        return effects;
    }

    // eslint-disable-next-line complexity
    function updateVglEffects(effects, prop) {
        // if we add a new effect the Vgl instance needs to re-init
        let needsInit = false;
        prop = prop || {};

        let bc = effects.brightnessContrast;
        let dt = effects.duotone;
        let hs = effects.hueSaturation;

        if (('brightness' in prop) || ('contrast' in prop)) {
            if (!bc) {
                bc = vgl.effects.brightnessContrast();
                effects.splice(1, 0, bc);
                effects.brightnessContrast = bc;
                needsInit = true;
            }

            if (prop.brightness || prop.brightness === 0) {
                bc.brightness = prop.brightness;
                bc.brightnessDsiabled = false;
            } else {
                bc.brightnessDsiabled = true;
            }

            if (prop.contrast || prop.contrast === 0) {
                bc.contrast = prop.contrast;
                bc.contrastDisabled = false;
            } else {
                bc.contrastDisabled = true;
            }
        } else if (bc) {
            bc.brightnessDsiabled = true;
            bc.contrastDisabled = true;
        }

        if (prop.duotoneDark && prop.duotoneLight) {
            if (!dt) {
                dt = vgl.effects.duotone();
                effects.splice(bc ? 2 : 1, 0, dt);
                effects.duotone = dt;
                needsInit = true;
            }

            dt.dark = hexToVec4(prop.duotoneDark);
            dt.light = hexToVec4(prop.duotoneLight);
            dt.disabled = false;
        } else if (dt) {
            dt.disabled = true;
        }

        if (('hue' in prop) || ('saturation' in prop)) {
            if (!hs) {
                hs = vgl.effects.hueSaturation();
                effects.push(hs);
                effects.hueSaturation = hs;
                needsInit = true;
            }

            if (prop.hue || prop.hue === 0) {
                hs.hue = prop.hue;
                hs.hueDisabled = false;
            } else {
                hs.hueDisabled = true;
            }

            if (prop.saturation || prop.saturation === 0) {
                hs.saturation = prop.saturation;
                hs.saturationDisabled = false;
            } else {
                hs.saturationDisabled = true;
            }
        } else if (hs) {
            hs.hueDisabled = true;
            hs.saturationDisabled = true;
        }

        return needsInit;
    }

    function getVglEffects(prop) {
        const effects = createVglEffects(prop);
        updateVglEffects(effects, prop);

        return effects;
    }

    function getEffectDefaults(prop) {
        if (prop && prop.effectType) {
            return svgFilters.getProperties(prop.effectType)
                .reduce((acc, effect) => {
                    let values = {};

                    if (effect) {
                        if (effect.duotone) {
                            values.duotoneDark = effect.duotone.dark;
                            values.duotoneLight = effect.duotone.light;
                        } else {
                            values = effect;
                        }
                    }

                    Object.assign(acc, values);

                    return acc;
                }, {});
        }

        return {};
    }

    function filterEffectPropToVglEffects(prop) {
        if (prop) {
            const data = getEffectDefaults(prop);
            Object.assign(data, prop);

            return data;
        }

        return {};
    }

    function hexToVec4(hex) {
        if (typeof hex === 'string') {
            const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

            return result ? [
                parseInt(result[1], 16) / 255,
                parseInt(result[2], 16) / 255,
                parseInt(result[3], 16) / 255,
                1
            ] : hex;
        }

        return hex;
    }

    function getVglSource(comp, media) {
        const props = comp.props;
        media = media || props.getMediaSource();

        return {
            type: 'video',
            width: (media && media.videoWidth) || 0,
            height: (media && media.videoHeight / 2) || 0,
            media
        };
    }

    function play() {
        if (!this.animationFrameId) {
            const loop = () => {
                this.animationFrameId = window.requestAnimationFrame(loop);
                const video = this.media;

                if (video && video.readyState >= video.HAVE_CURRENT_DATA && (!(video.paused || video.ended) || this._needsRedraw)) {
                    this._needsRedraw = false;
                    this.draw();
                }
            };

            this.animationFrameId = window.requestAnimationFrame(loop);
        }
    }

    vgl.Kampos.prototype.play = play;

    return {
        displayName: 'webglMedia',
        mixins: [componentsCore.mixins.skinBasedComp],
        propTypes: {
            notifyMediaState: PropTypes.func,
            getMediaSource: PropTypes.func,
            showMedia: PropTypes.bool,
            filterEffect: PropTypes.object,
            notifyWebGLVisibility: PropTypes.func,
            getIsVisible: PropTypes.func,
            addVisibilityStateListener: PropTypes.func,
            addWebGLContext: PropTypes.func,
            removeWebGLContext: PropTypes.func
        },

        getInitialState() {
            this.kampos = null;
            this.isVisible = null;
            return {};
        },

        getIsVisible() {
            if (this.isVisible === null) {
                this.isVisible = this.props.getIsVisible();
            }

            return this.isVisible;
        },

        componentDidMount() {
            this.removeVisibilityStateListener = this.props.addVisibilityStateListener(state => {
                if (state.in && state.isPlayingAllowed) {
                    this.isVisible = true;
                    this.initVglInstance();
                } else if (this.kampos) {
                    this.isVisible = false;
                    this.stopVglInstance();
                }
            });
        },

        componentDidUpdate() {
            const shouldPlay = this.getIsVisible();
            const hasLiveInstance = this.kampos && this.kampos.gl;

            if (shouldPlay) {
                if (hasLiveInstance) {
                    this.updateVglInstance();
                } else {
                    this.initVglInstance();
                }
            } else if (hasLiveInstance) {
                this.stopVglInstance();
            }
        },

        componentWillUnmount() {
            if (this.kampos) {
                if (this.kampos.gl) {
                    this.kampos.destroy();
                }
                this.kampos = null;

                if (this.hasContext) {
                    this.props.removeWebGLContext();
                    this.hasContext = false;
                }
            }

            this.removeVisibilityStateListener();
            this.removeVisibilityStateListener = () => {};
        },

        componentDidLayout() {
            if (this.kampos && this.kampos.media) {
                this.kampos.setSource(getVglSource(this, this.kampos.media));
            }
        },

        // eslint-disable-next-line complexity
        initVglInstance() {
            const hasLiveInstance = this.kampos && this.kampos.gl;

            if (hasLiveInstance) {
                this.props.notifyWebGLVisibility(true);
                this.kampos.play();
                return;
            } else if (vgl.Kampos.preventContextCreation) {
                this.props.notifyWebGLVisibility(false);
                return;
            }

            const canCreateContext = this.props.addWebGLContext();

            if (canCreateContext) {
                this.hasContext = true;

                if (this.kampos) {
                    if (this.kampos.lostContext) {
                        this.kampos.restoreContext();
                    } else {
                        updateVglEffects(this.kampos.config.effects, filterEffectPropToVglEffects(this.props.filterEffect));
                        this.kampos.init();
                        this.kampos.setSource(this.kampos._source || getVglSource(this, this.kampos.media));

                        delete this.kampos._source;
                    }

                    this.kampos.play();
                } else {
                    /*
                     * initialize a Vgl instance
                     */
                    const target = this.refs.canvas;
                    const effects = getVglEffects(filterEffectPropToVglEffects(this.props.filterEffect));

                    try {
                        this.kampos = new vgl.Kampos({
                            target,
                            effects,
                            onContextLost: () => {
                                const video = this.kampos._source && this.kampos._source.media;

                                if (this.props.showMedia && video && (video.ended || video.paused)) {
                                    this.kampos._needsRedraw = true;
                                }

                                if (this.getIsVisible()) {
                                    this.initVglInstance();
                                }
                            }
                        });

                        this.kampos.setSource(getVglSource(this));

                        this.kampos.play();
                    } catch (e) {
                        /*
                         * failed to initialize Vgl
                         */
                        this.props.removeWebGLContext();
                        this.hasContext = false;

                        this.props.notifyMediaState({
                            type: mediaConsts.eventTypes.ERROR,
                            error: mediaConsts.errorTypes.WEBGL_ERROR
                        });
                    }
                }

                this.props.notifyWebGLVisibility(true);
            } else {
                this.props.notifyWebGLVisibility(false);
            }
        },

        // eslint-disable-next-line complexity
        updateVglInstance() {
            if (this.kampos) {
                const props = this.props;
                const media = props.getMediaSource();

                if (media) {
                    const source = getVglSource(this, media);

                    let updateEffects = true;

                    if (!this.kampos.lostContext && this.kampos.gl) {
                        /*
                         * Set source, play, and update effects
                         */
                        try {
                            this.kampos.setSource(source);

                            this.kampos.play();

                            if (!this.props.showMedia) {
                                this.props.notifyWebGLVisibility(true);
                            }
                        } catch (e) {
                            updateEffects = false;

                            this.props.notifyMediaState({
                                type: mediaConsts.eventTypes.ERROR,
                                error: mediaConsts.errorTypes.WEBGL_ERROR
                            });
                        }

                        if (updateEffects) {
                            const newEffects = props.filterEffect;

                            // mutates the instance's effects list *in-place*
                            const needsInit = updateVglEffects(this.kampos.config.effects, filterEffectPropToVglEffects(newEffects));

                            if (needsInit) {
                                this.kampos.init();
                            }
                        }
                    }
                }
            }
        },

        stopVglInstance() {
            this.kampos.stop();

            if (this.hasContext && !vgl.Kampos.preventContextCreation) {
                this.props.removeWebGLContext();
                this.hasContext = false;
            }
        },

        getSkinProperties() {
            return {
                '': {
                    style: {
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        overflow: 'hidden',
                        opacity: this.props.showMedia ? 1 : 0
                    }
                },
                canvas: {
                    style: {
                        position: 'absolute'
                    }
                }
            };
        }
    };
});
